import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import { MobileHeader } from './MobileHeader';
import { SliderVertical } from './slider/SliderVertical';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderSecondRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderSecondRowRef.current) {
        setHeaderHeight(desktopHeaderSecondRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderSecondRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  return (
    <>
      {!headerCheckout ? (
        <div className="sticky-top">
          <TopBar content={content} />
          <MobileHeader
            content={content}
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            content={content}
            firstRowRef={desktopHeaderSecondRowRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleFrom="xl"
          />
        </div>
      ) : (
        <b2x.HeaderCheckout
          desktopCenterItemStyle="h5 m-0"
          desktopRightItemStyle="extra-small fw-light text-dark text-uppercase"
          isSticky
          logo={logo}
          logoSmall={logoSmall}
          mobileCenterItemStyle="extra-small fw-light text-dark text-uppercase"
          mobileFrom={'lg'}
          recalculateHeaderHeight={recalculateHeaderHeight}
          wrapperStyle="shadow bg-white border-bottom"
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
}

const TopBar = ({ content }: TopBarProps) => {
  const topBarRef = React.useRef<HTMLDivElement>(null);
  const { setTopBarDesktopHeight } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRef]);

  return (
    <div
      className={'top-bar'}
      ref={topBarRef}
      style={{
        background: content?.body.topBar?.backgroundColor ? content.body.topBar.backgroundColor : '#FF2954',
        color: content?.body.topBar?.textColor ? content.body.topBar.textColor : '#FFFFFF',
      }}
    >
      <Container>
        <b2x.Row>
          <b2x.Col className="d-none d-lg-flex align-items-center extra-small" size={4}>
            {content?.body.topBar?.leftColumn?.ctaList?.map(
              (item) =>
                item.cta && (
                  <b2x.DeprecatedCta
                    className="text-white text-decoration-none me-4"
                    cta={item.cta}
                    key={item.contentSectionId}
                  >
                    {item.cta.icon && isIconName(item.cta.icon) && (
                      <Icon className="me-1" name={item.cta.icon} size={16} />
                    )}
                    {item.cta.label}
                  </b2x.DeprecatedCta>
                )
            )}
          </b2x.Col>
          <b2x.Col className="extra-small fw-bold" size={{ lg: 4, sm: 12 }}>
            {content?.body.topBar?.centerColumn?.sliderVertical?.itemList ? (
              <SliderVertical itemList={content.body.topBar.centerColumn.sliderVertical.itemList} />
            ) : (
              <span className="extra-small fw-light text-uppercase">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-end extra-small" size={4}>
            {content?.body.topBar?.rightColumn?.cta && (
              <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.topBar.rightColumn.cta}>
                {content.body.topBar.rightColumn.cta.icon && isIconName(content.body.topBar.rightColumn.cta.icon) && (
                  <Icon className="me-1" name={content.body.topBar.rightColumn.cta.icon} size={16} />
                )}
                {content.body.topBar.rightColumn.cta.label}
              </b2x.DeprecatedCta>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
