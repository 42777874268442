import { ImageThronContentSection } from './contentTypes';
import { ImageThron, ImageThronProps } from './ImageThron';

export interface ImageThronFromContentProps extends ImageThronContentSection, Omit<ImageThronProps, 'src'> {}

export const ImageThronFromContent = ({ lg, md, sm, xl, xs, xxl, ...otherProps }: ImageThronFromContentProps) => {
  return (
    <>
      {xs?.url && (
        <ImageThron
          {...otherProps}
          quality={{
            lg: lg?.quality,
            md: md?.quality,
            sm: sm?.quality,
            xl: xl?.quality,
            xs: xs.quality,
            xxl: xxl?.quality,
          }}
          src={{
            lg: lg?.url,
            md: md?.url,
            sm: sm?.url,
            xl: xl?.url,
            xs: xs.url,
            xxl: xxl?.url,
          }}
        />
      )}
    </>
  );
};
