import classnames from 'classnames';
import React from 'react';

import { Breakpoint } from './interfaces';
import { getBreakpointValue, renderUI } from './util';

export interface ImageThronProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'width'> {
  alt?: string;
  aspectRatio?: number | ResponsiveSize;
  className?: string;
  fluid?: boolean;
  quality?: number | ResponsiveSize;
  src?: string | ResponsiveSrc;
  title?: string;
  width?: number | ResponsiveSize;
}

export type ResponsiveSrc = Partial<Record<Breakpoint, string>>;
//export type DevicePixelRatio = Partial<Record<Breakpoint, 1 | 2 | 3 | 4>>;
export type ResponsiveSize = Partial<Record<Breakpoint, number>>;

export const ImageThron = ({
  alt,
  aspectRatio,
  className,
  fluid,
  onLoad,
  quality,
  src,
  width,
  ...otherProps
}: ImageThronProps) => {
  const sourcesBreakpoint: Array<keyof ResponsiveSrc> = ['xxl', 'xl', 'lg', 'md', 'sm'];

  const getThronImageSrc = (
    _path: string,
    _width?: number,
    _height?: number,
    _dpr?: number,
    _quality?: number
  ): string => {
    const url = new URL(_path);
    url.searchParams.set('crop', 'centered');

    if (_width && _width > 0) {
      url.searchParams.set('w', _width.toString());
    }
    if (_height && _height > 0) {
      url.searchParams.set('h', _height.toString());
    }
    if (_dpr) {
      url.searchParams.set('dpr', _dpr.toString());
    }
    if (_quality && _quality >= 5 && _quality <= 95) {
      url.searchParams.set('q', _quality.toString());
    }
    return url.toString();
  };

  const getWidthFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSize) => {
      let _width: number | undefined;
      if (typeof width === 'number' && width > 0) {
        _width = width;
      } else if (typeof width === 'object') {
        _width = width[breakpoint];
      }
      return _width;
    },
    [width]
  );

  const getAspectRatioFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSize) => {
      let _aspectRatio: number | undefined;

      if (typeof aspectRatio === 'number' && aspectRatio > 0) {
        _aspectRatio = aspectRatio;
      } else if (typeof aspectRatio === 'object') {
        _aspectRatio = aspectRatio[breakpoint];
      }
      return _aspectRatio;
    },
    [aspectRatio]
  );

  const getHeightFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSize) => {
      const _width = getWidthFromBreakpoint(breakpoint);
      const _ratio = getAspectRatioFromBreakpoint(breakpoint);

      return _width && _ratio && Math.floor(_width / _ratio);
    },
    [getAspectRatioFromBreakpoint, getWidthFromBreakpoint]
  );

  const getQualityFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSize) => {
      let q: number | undefined;

      if (typeof quality === 'number' && quality > 0) {
        q = quality;
      } else if (typeof quality === 'object') {
        q = quality[breakpoint];
      }
      return q;
    },
    [quality]
  );

  const getThronImageFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSrc, dpr = 2) => {
      const _width = getWidthFromBreakpoint(breakpoint);
      const _height = getHeightFromBreakpoint(breakpoint);
      const _quality = getQualityFromBreakpoint(breakpoint);
      let breakpointSrc: string | undefined;
      if (typeof src === 'string') {
        breakpointSrc = src;
      } else if (typeof src === 'object') {
        breakpointSrc = src[breakpoint];
      }

      return breakpointSrc && getThronImageSrc(breakpointSrc, _width, _height, dpr, _quality);
    },
    [getHeightFromBreakpoint, getQualityFromBreakpoint, getWidthFromBreakpoint, src]
  );

  const getThronSrcSetFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSrc) => {
      const devicePixelRatio = [1, 2];
      return devicePixelRatio.map((dpr) => `${getThronImageFromBreakpoint(breakpoint, dpr)} ${dpr}x`).join(', ');
    },
    [getThronImageFromBreakpoint]
  );

  return (
    <>
      <picture className="">
        {sourcesBreakpoint.map(
          (sourceBreakpoint) =>
            getThronImageFromBreakpoint(sourceBreakpoint) && (
              <source
                height={getHeightFromBreakpoint(sourceBreakpoint)}
                key={sourceBreakpoint}
                media={`(min-width: ${getBreakpointValue(sourceBreakpoint)}px)`}
                srcSet={getThronSrcSetFromBreakpoint(sourceBreakpoint)}
                width={getWidthFromBreakpoint(sourceBreakpoint)}
              />
            )
        )}
        {renderUI({
          bs5: (
            // eslint-disable-next-line react/forbid-elements, jsx-a11y/alt-text
            <img
              {...otherProps}
              className={classnames({ 'img-fluid': fluid }, className)}
              height={getHeightFromBreakpoint('xs')}
              src={getThronImageFromBreakpoint('xs')}
              srcSet={getThronSrcSetFromBreakpoint('xs')}
              style={{ height: 'auto' }}
              width={getWidthFromBreakpoint('xs')}
            />
          ),
        })}
      </picture>
    </>
  );
};
