import { PaymentMethodApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCartApi } from './api/useCartApi';
import { useContentsApi } from './api/useContentsApi';
import { useOrdersApi } from './api/useOrdersApi';
import { useAppContext } from './AppContext';
import { PaymentMethodsContentType } from './contentTypes';
import { ResourceKeys, t } from './i18n/i18n';

export interface UsePaymentMethodsProps {
  orderCode?: string;
}

export interface UsePaymentMethodsResult {
  paymentMethods: Array<PaymentMethod>;
}

export interface PaymentMethod extends PaymentMethodApiDto {
  images?: Array<string>;
}

export const usePaymentMethods = ({ orderCode }: UsePaymentMethodsProps): UsePaymentMethodsResult => {
  const [paymentMethodsFromApi, setPaymentMethodsFromApi] = React.useState<Array<PaymentMethod>>();
  const [paymentMethods, setPaymentMethods] = React.useState<Array<PaymentMethod>>([]);

  const { getPaymentMethods: getPaymentMethodsFromCart } = useCartApi();
  const { getPaymentMethods: getPaymentMethodsFromOrder } = useOrdersApi();
  const { getContentByCode } = useContentsApi();
  const { session } = useAppContext();

  React.useEffect(() => {
    if (orderCode) {
      getPaymentMethodsFromOrder(orderCode).then((response) => {
        setPaymentMethodsFromApi(response.data);
      });
    }
  }, [getPaymentMethodsFromOrder, orderCode]);

  // Prendo i paymentMethods al primo caricamento e quando cambia il carrello in sessione (potrebbero cambiare le soglie)
  React.useEffect(() => {
    if (!orderCode) {
      getPaymentMethodsFromCart().then((response) => {
        setPaymentMethodsFromApi(response.data);
      });
    }
  }, [getPaymentMethodsFromCart, orderCode, session?.cart]);

  React.useEffect(() => {
    if (paymentMethodsFromApi) {
      getContentByCode<PaymentMethodsContentType>('PAYMENT_METHODS_CONTENT', undefined, { suppressErrorLog: true })
        .then((contentResponse) => {
          console.log('contentResponse', contentResponse);
          const _paymentMethods: Array<PaymentMethod> = [];
          const paymentMethodsFromContent = contentResponse.data.body.paymentMethods;
          // ciclo i metodi di pagamento impostati nel contenuto
          paymentMethodsFromContent?.forEach((paymentMethodFromContent) => {
            console.log('paymentMethodFromContent', paymentMethodFromContent);
            // prendo l'eventuale metodo di pagamento iterato, ma dagli ApiDto
            const paymentMethodFromApi = paymentMethodsFromApi.find(
              ({ type }) => type === paymentMethodFromContent.type
            );
            console.log('paymentMethodFromApi 1', paymentMethodFromApi);
            // Se c'è continuo, se no vuol dire che da contenuto l'ho messo ma da configurazione non c'è, qjuindi va saltato
            if (paymentMethodFromApi !== undefined) {
              // lo aggiungo partendo dall'ApiDto e modificandolo con le cose da contenuto
              _paymentMethods.push({
                ...paymentMethodFromApi,
                description: paymentMethodFromContent.description,
                images: paymentMethodFromContent.images
                  ?.filter(({ image }) => image?.url !== undefined)
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                  ?.map(({ image }) => image?.url!),
                name: paymentMethodFromContent.name || t(`paymentMethod.${paymentMethodFromApi.type}` as ResourceKeys),
              });
              console.log('push da content', _paymentMethods);
            }
          });
          // Vedo se ci sono altri metodi di pagamento da gestire che non stavano nel contenuto.
          paymentMethodsFromApi.forEach((paymentMethodFromApi) => {
            console.log('paymentMethodFromApi 2', paymentMethodFromApi);
            // Se il paymentMethodApiDto iterato non stava gia nel contenuto, lo aggiungo così come è.
            if (
              paymentMethodsFromContent?.findIndex(
                (paymentMethodFromContent) => paymentMethodFromContent.type === paymentMethodFromApi.type
              ) === -1
            ) {
              _paymentMethods.push({
                ...paymentMethodFromApi,
                name: t(`paymentMethod.${paymentMethodFromApi.type}` as ResourceKeys),
              });
              console.log('push da api', _paymentMethods);
            }
          });
          console.log('_paymentMethods', _paymentMethods);
          setPaymentMethods(_paymentMethods);
        })
        .catch(() => {
          setPaymentMethods(paymentMethodsFromApi);
        });
    }
  }, [getContentByCode, paymentMethodsFromApi]);

  return { paymentMethods };
};
